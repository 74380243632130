<template>
	<div class="AboutSongDetail">
		<w-navTab titleText="协会会歌"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="header">上饶义警之歌MMM2程凌昭</div>
			<div class="body">
				
			</div>
			<div class="footer">
				<audio controls="controls">
					<source src="@/assets/audio/mp.mp3">
				</audio>
			</div>
		</div>
	</div>
</template>

<script>
import NewsApi from '@/api/news'
import UtilsHelper from '@/vendor/utils';
import LoadingPage from '@/components/LoadingPage';
import PlayerVendor from '@/vendor/player';
	
export default {
	name: 'AboutSongDetail',
	data() {
		return {
			loadingPage: true,
			// content: '',
			// articleId: UtilsHelper.getCurrentDistrictConfig().column.emblem
		};
	},
	created() {
		
		
		
		this.loadingPage = false
		// let params = {
		// 	article_id: this.articleId
		// }
		
		// NewsApi.articleDetail(params)
		// 	.then(result => {
		// 		this.content = result.data.content;
		// 	})
		// 	.catch(error => {
		// 		console.log(error);
		// 	})
	},
	destroyed() {
		
	},
	methods: {
		
	},
	components: {
		LoadingPage
	}
};
</script>

<style scoped lang="less">
	.AboutSongDetail{
		width: 100%;
		min-height: 100vh;
		background-color: #FFFFFF;
		padding-bottom: 20px;
		box-sizing: border-box;
		
		.container{
			
			.header{
				padding: 47px 0 24px;
				font-size: 22px;
				font-weight: bold;
				line-height: 36px;
				text-align: center;
				color: #333;
				box-sizing: border-box;
			}
			.body{
				
			}
			.footer{
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
</style>